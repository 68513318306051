@font-face {
  font-family: "OpenSans";
  src: url("./assets/fonts/OpenSansRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSansCondensed";
  src: url("./assets/fonts/OpenSansCondensed.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  color-scheme: only light;
}

body {
  font-family: OpenSans !important;
}

* {
  scrollbar-width: none;
  scrollbar-color: grey;
  font-family: OpenSans;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:vertical {
  background: transparent;
}

* {
  scrollbar-color: transparent transparent;
}

nav {
  background-color: #fff !important;
  transition: all 0.3s linear;
}

#hero {
  margin-top: 80px;
  margin-bottom: 1.5rem;
}

.open-nav {
  height: 220px;
  overflow: hidden;
}

.profile {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.nav-link {
  font-size: 16px !important;
  font-weight: bold !important;
  transform: all 0.3s linear !important;
}

/* Profile icon Styling */
.nav-link-home {
  color: black;
}

.nav-large-username p:nth-child(2) {
  font-size: 12px;
}

.nav-link-home:hover {
  color: var(--bs-nav-link-color);
}

.navbar-nav .nav-item:hover .nav-link::after {
  transform: translateX(-50%) scale(1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.user-name {
  color: gray !important;
  font-weight: bold;
}

.navbar {
  position: relative;
  height: 82px;
}

.btn-close-logout {
  border-radius: 50%;
  border: none;
  width: 25px;
  height: 25px;
  background: #50b482;
  color: white !important;
}

.modal-dialog .modal-content {
  border-radius: 21px;
}

.btn-close-logout:hover {
  background: #42cb86;
}

.toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: gray;
}

.navbar-onb-logo {
  filter: drop-shadow(0px 0px 1px rgba(252, 252, 252, 0.9));
}

.mobile-nav {
  background: white;
  position: fixed;
  width: 200px;
  right: -200px;
  top: 0;
  bottom: 0;
  transition: all 0.3s linear;
  z-index: 10;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.open-mobile-nav {
  right: 0;
}

.close-icon {
  font-size: 1.5rem;
  color: black;
}

.close-btn {
  background: transparent;
  border: none;
}

.speak-test,
.predictive {
  min-height: 450px;
  border-radius: 8px;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  margin: 0 2px;
  border-bottom: 7.5px solid rgba(80, 180, 130, 1);
  position: relative;
}

.speak-test.lang-change,
.predictive.lang-change {
  min-height: 450px;
}

@media (min-width: 1220px) and (max-width: 1490px) {

  .speak-test,
  .predictive {
    min-height: 520px !important;
  }
}

@media (min-width: 1106px) and (max-width: 1280px) {

  .speak-test.lang-change,
  .predictive.lang-change {
    min-height: 580px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1217px) {

  .speak-test,
  .predictive {
    min-height: 450px !important;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {

  .speak-test,
  .predictive {
    min-height: 600px !important;
  }
}

@media (min-width: 992px) and (max-width: 1093px) {

  .speak-test.lang-change,
  .predictive.lang-change {
    min-height: 600px !important;
  }
}

@media (max-width: 420px) {

  .speak-test.lang-change,
  .predictive.lang-change {
    min-height: 550px !important;
  }
}

.test-title {
  font-size: 1.7rem;
  font-weight: bold;
  color: white;
}

.user-dash-div {
  border-radius: 16px;
  background: #f5f5f5;
  border: 4px solid transparent;
  flex: 1;
  margin: 0 2px;
}

.test-section {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
}

.upload-content {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  min-height: 120px;
  color: white;
}

.content {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  color: white !important;
}

.plan {
  text-decoration: none;
  color: #005ce7;
  font-weight: 600;
}

.cvUpload {
  width: auto;
  height: 130px;
  object-fit: contain;
  position: absolute;
  bottom: 2%;
  right: 2%;
}

.robot {
  width: auto;
  height: 140px;
  object-fit: cover;
  position: absolute;
  bottom: 1%;
  right: 3%;
}

.report_polygon {
  width: 360px;
  height: 360px;
  object-fit: contain;
}

@media (min-width: 992px) and (max-width: 1092px) {
  .cvUpload {
    height: 100px;
  }

  .robot {
    height: 100px;
  }
}

.report-chart {
  min-height: 300px;
}

.level-text {
  font-size: 32px;
  padding: 0 0 0 3%;
  bottom: 140px;
}

.test-start {
  text-decoration: none;
  border-radius: 5px;
  background: #50b482;
  width: 130px;
  height: 40px;
  text-align: center;
  color: white;
  line-height: 2.5;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s linear;
  display: block;
  text-transform: capitalize;
}

.test-start:hover {
  background: #8a56ce;
  color: white;
}

.user-dash-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-top: 1.8rem;
}

.faq-prepare {
  border-radius: 8px;
  background: #f5f5f5;
  border: 4px solid transparent;
  border-bottom-color: #04d39c;
}

.faq-heading {
  font-weight: bold;
  color: #000;
  text-transform: capitalize;
}

.question {
  font-size: 14px;
  font-weight: bold;
  color: black;
  font-family: "OpenSans" sans-serif !important;
}

.answer {
  font-weight: normal;
  font-size: 14px !important;
  color: #615e64;
  min-height: fit-content !important;
  font-family: "OpenSans" sans-serif !important;
}

@media (max-width: 992px) and (min-width: 1242px) {
  .answer {
    min-height: 180px !important;
  }
}

.question-section {
  background: rgba(240, 243, 246, 1);
  border-radius: 7px;
}

.cv-faq-header.active {
  background: #56367f;
  border-radius: 7px;
}

.collapse-area {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.question-active {
  color: #ffffff;
}

.faq-rocket {
  height: 350px;
  width: auto;
}

.faq-work {
  height: 300px;
  width: auto;
}

@media screen and (max-width: 768px) {
  .faq-rocket {
    height: 250px;
  }

  .faq-work {
    height: 220px;
  }
}

.cant-find {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.email {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  opacity: 0.8;
}

.info-email-container {
  position: relative;
  overflow: hidden;
  border-radius: 22px;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
}

.info-bg p:last-child {
  border-radius: 7px;
  padding: 7px 15px !important;
  background: #50b482;
  width: fit-content;
}

.email-link {
  text-decoration: none;
  color: #fff;
}

.email-link:hover {
  text-decoration: underline;
  color: white;
}

.bg-footer {
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  height: 150px;
  width: 100%;
}

.social-icon {
  border-radius: 50%;
  background-color: white;
  font-size: 1rem;
  height: 40px;
  width: 40px;
  padding: 9px;
  color: #56367f;
  cursor: pointer;
  transition: all 0.3s linear;
}

.social-icon:hover {
  background-color: #000;
  color: white;
  border-color: black;
}

.copy-right {
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  text-align: center;
}

.navigate-tour {
  position: fixed;
  z-index: 3;
  right: 4rem;
  bottom: 3rem;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  border: 1px solid white !important;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  animation: pop-up 2s ease infinite;
  border: none;
}

.navigate-up:hover {
  color: white;
}

.navigate-up {
  position: fixed;
  z-index: 1;
  right: 8rem;
  bottom: 3rem;
  background: gray;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  animation: pop-up 2s ease infinite;
  border: none;
}

@media screen and (max-width: 768px) {
  .navigate-tour {
    right: 2rem;
    bottom: 1rem;
  }

  .navigate-up {
    right: 6rem;
    bottom: 1rem;
  }
}

.select-test-container {
  padding: 0 !important;
}

.container-bar {
  width: 60%;
  margin: 0 auto;
}

.bar-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.bar-progress::before {
  content: "";
  position: absolute;
  background-color: #f3f3f3;
  left: 33px;
  border-radius: 5px;
  height: 10px;
  z-index: -1;
  transform: translateY(-50%);
  transition: all 1s ease;
  width: 90%;
  top: 62%;
}

.progres {
  position: absolute;
  background: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%), #fff;
  width: 50%;
  height: 10px;
  z-index: -1;
  top: 62%;
  left: 33px;
  transform: translateY(-50%);
  transition: all 1s ease;
  border-radius: 5px;
}

.circle-cv {
  background-color: #f5f5f5;
  height: 65px;
  width: 65px;
  font-size: 16px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: 3px solid #f5f5f5;
  border-radius: 50%;
  transition: all 1s ease;
  flex-direction: column;
}

.circle {
  background-color: #fff;
  height: 65px;
  width: 65px;
  font-size: 16px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: 3px solid #dddfe1;
  border-radius: 50%;
  transition: all 1s ease;
  flex-direction: column;
}

.circle-cv.active {
  background: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%), #fff;
  color: white;
  font-weight: bold;
  border-image: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%);
  border-image-slice: 1;
}

.circle.active {
  background: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%), #fff;
  color: white;
  font-weight: bold;
  border-image: linear-gradient(90deg, #3790b7 0%, #00a9ff 100%);
  border-image-slice: 1;
}

.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: -11px;
  top: 18px;
}

.caption {
  margin-top: 8px;
  text-align: center;
}

.caption-bar {
  font-weight: bold;
  color: #464a53;
  font-size: 16px;
  position: relative;
  top: 10px;
}

@keyframes pop-up {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@media (max-width: 1166px) {
  .content br {
    display: none;
  }
}

@media (min-width: 260px) and (max-width: 470px) {
  .answer {
    font-size: 15px;
  }
}

@media (max-width: 1040px) {
  .test-section {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 1060px) {
  .report_polygon {
    width: 280px;
    height: 280px;
    object-fit: contain;
    margin-top: 2rem;
  }

  .level-text {
    font-size: 32px;
    padding: 0 0 0 7%;
    bottom: 126px;
  }
}

@media (max-width: 990px) {
  .report_polygon {
    width: 250px;
    height: 250px;
    object-fit: contain;
    margin-top: 4rem;
  }

  .level-text {
    font-size: 32px;
    padding: 0 0 0 7%;
    bottom: 110px;
  }
}

@media (max-width: 900px) {
  .report_polygon {
    width: 240px;
    height: 240px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 32px;
    padding: 0 0 0 7%;
    bottom: 100px;
  }
}

@media (max-width: 870px) {
  .report_polygon {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-top: 6rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 7%;
    bottom: 98px;
  }
}

@media (max-width: 767px) {
  .report_polygon {
    width: 280px;
    height: 280px;
    object-fit: contain;
    margin-top: 1rem;
    margin-left: -5rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 11% 0 0;
    bottom: 136px;
  }
}

@media (max-width: 600px) {
  .report_polygon {
    width: 280px;
    height: 280px;
    object-fit: contain;
    margin-top: 1rem;
    margin-left: -1rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 136px;
  }
}

@media (max-width: 445px) {
  .report_polygon {
    width: 250px;
    height: 250px;
    object-fit: contain;
    margin-top: 3rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 120px;
  }
}

@media (max-width: 400px) {
  .report_polygon {
    width: 230px;
    height: 230px;
    object-fit: contain;
    margin-top: 3rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 120px;
  }
}

@media (max-width: 390px) {
  .report_polygon {
    width: 220px;
    height: 220px;
    object-fit: contain;
    margin-top: 4rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 112px;
  }
}

@media (max-width: 375px) {
  .report_polygon {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 30px;
    padding: 0 0 0 0;
    bottom: 108px;
  }
}

@media (max-width: 350px) {
  .report_polygon {
    width: 180px;
    height: 180px;
    object-fit: contain;
    margin-top: 5rem;
    margin-left: -1rem;
  }

  .level-text {
    font-size: 20px;
    padding: 0 0 0 0%;
    bottom: 115px;
  }
}

@media (max-width: 330px) {
  .report_polygon {
    width: 150px;
    height: 150px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 20px;
    padding: 0 5% 0 0;
    bottom: 115px;
  }
}

@media (max-width: 300px) {
  .report_polygon {
    width: 130px;
    height: 130px;
    object-fit: contain;
    margin-top: 5rem;
  }

  .level-text {
    font-size: 20px;
    padding: 0 5% 0 0;
    bottom: 120px;
  }
}

@media (max-width: 280px) {
  .report_polygon {
    width: 110px;
    height: 110px;
    object-fit: contain;
    margin-top: 7rem;
  }

  .level-text {
    font-size: 15px;
    padding: 0 5% 0 0;
    bottom: 107px;
  }
}

@media (max-width: 410px) {
  .cvUpload {
    height: 170px;
    width: 170px;
  }

  .robot {
    height: 150px;
    width: auto;
  }

  .polygon {
    height: 250px;
    width: 250px;
  }

  .content {
    font-size: 14px;
  }
}

@media (max-width: 417px) {
  .question {
    font-size: 16px;
  }
}

@media (max-width: 560px) {
  .logo-rocket {
    display: none !important;
  }
}

@media (min-width: 560px) and (max-width: 725px) {
  .cant-find {
    font-size: 34px;
  }
}

@media (min-width: 470px) and (max-width: 559px) {
  .cant-find {
    font-size: 28px;
  }

  .email {
    font-size: 16px;
  }
}

@media (min-width: 260px) and (max-width: 470px) {
  .cant-find {
    font-size: 22px;
  }

  .email {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .social-icon {
    height: 45px;
    width: 45px;
  }
}

@media (max-width: 334px) {
  .cvUpload {
    height: 150px;
    width: 150px;
  }

  .polygon {
    height: 200px;
    width: 200px;
  }

  .robot {
    height: 150px;
    width: 150px;
  }

  .cant-find {
    font-size: 18px;
  }

  .email {
    font-size: 13px;
  }
}

@media (max-width: 414px) {
  .mobile-nav {
    width: 100%;
    right: -420px;
  }

  .open-mobile-nav {
    right: 0px;
  }

  .copy-write {
    font-size: 14px;
  }
}

@media (min-width: 945px) and (max-width: 1100px) {
  .container-bar {
    width: 70%;
  }
}

@media (min-width: 850px) and (max-width: 944px) {
  .container-bar {
    width: 80%;
  }
}

@media (min-width: 450px) and (max-width: 849px) {
  .container-bar {
    width: 80%;
  }

  .bar-progress::before {
    left: 7px;
  }

  .progres {
    left: 7px;
  }
}

@media (max-width: 580px) {
  .caption-bar {
    font-size: 14px;
  }

  .bar-progress::before {
    left: 7px;
  }

  .progres {
    left: 7px;
  }

  .circle-container {
    left: -5px;
  }
}

@media (max-width: 490px) {
  .bar-progress::before {
    left: 20px;
  }

  .progres {
    left: 20px;
  }

  .caption-bar {
    font-size: 14px;
  }

  .circle {
    height: 50px;
    width: 50px;
    font-size: 14px;
  }
}

@media (max-width: 449px) {
  .container-bar {
    width: 100%;
  }
}

@media (max-width: 280px) {
  .container-bar {
    width: 100%;
  }

  .bar-progress::before {
    left: 20px;
  }

  .progres {
    left: 20px;
  }

  .caption-bar {
    font-size: 12px;
  }

  .circle {
    height: 45px;
    width: 45px;
    font-size: 14px;
  }
}

/* Dashboard Styling */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.close-div {
  position: absolute;
  right: 35px;
  top: 15px;
  font-size: 1.7rem;
  display: none !important;
}

.sidebar {
  width: 300px;
  transition: all 0.3s linear;
}

.sidebar-collapse {
  width: 0;
  overflow: hidden;
}

.open-sidebar {
  width: 300px !important;
  overflow: visible !important;
}

.side-title {
  font-size: 20px;
  margin-top: 3.25rem;
  color: #5f00d9;
}

.side-content {
  height: 88%;
}

.footer-items {
  font-size: 14px;
}

.bi-telephone {
  color: #50b482;
}

.mail-icon {
  font-size: 18px !important;
  color: #50b482;
}

.nav-items.menu-item {
  padding: 20px 0px;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-items-2 {
  transition: all 0.3s linear;
  cursor: pointer;
  color: #797e82;
  font-weight: 500;
  border-radius: 10px;
  gap: 1rem;
  white-space: nowrap;
}

.nav-items-2:hover {
  background: white;
  color: black;
}

.top-nav {
  padding: 1rem 10rem;
  background: #f5f5f5;
}

.btn-user {
  font-size: 2rem !important;
}

.btn-toggle {
  font-size: 2rem !important;
}

.background {
  background-color: #eeeef4;
  padding: 24px 0;
}

.dashboard-top {
  margin: 0rem 10rem;
  padding: 0rem 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.total {
  background: #f5f5f5;
  padding: 1rem;
  border-radius: 10px;
  text-align: left;
}

.caption {
  font-size: 14px;
  font-weight: 500;
  color: #535d66;
  margin-bottom: 8px;
  text-shadow: 0 0 #535d66;
  text-align: left;
}

.content {
  font-size: 24px;
  font-weight: 500;
  color: #171717;
}

.valid-user {
  font-size: 2rem;
}

.tag {
  display: flex;
  padding: 4px 10px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 100px;
  background: #f3f3f7;
  font-size: 12px;
  font-weight: 500;
  text-shadow: 0 0 #797e82;
  color: #797e82;
  position: relative;
  top: -4px;
  letter-spacing: 1px;
}

.balance {
  gap: 9rem;
}

.btn-deposit,
.btn-withdraw {
  height: 40px;
  width: 130px;
  border-radius: 10px;
  background-color: #43addb;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  font-weight: 500;
  text-shadow: 0 0 white;
  transition: all 0.3s linear;
  outline: 0;
  border: 0;
}

.btn-deposit:focus,
.btn-withdraw:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.transactions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem 10rem;
  gap: 2rem;
}

.price-side {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 1.2rem;
  position: relative;
  overflow-x: auto;
}

.trans-side {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 1.2rem;
}

.profit {
  color: #f5f5f5;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  top: -2px;
}

.tab {
  border: none;
  height: 25px;
  width: 30px;
  font-size: 14px;
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #797e82;
  font-weight: 500;
  font-size: 13px;
}

.tab:focus {
  background-color: white;
}

.tab-active {
  background-color: white;
}

.tab:hover {
  background-color: white;
}

.date {
  font-size: 12px;
  color: #797e82;
  margin-bottom: 0;
  margin-top: 1rem;
}

.img-area {
  position: relative;
}

.graph {
  width: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
}

.line {
  width: 100%;
  object-fit: cover;
}

.dollar {
  height: 40px;
  width: 40px;
  background: #f3f3f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.1rem;
  text-shadow: 0 0 #171717;
  font-weight: 500;
}

.date-time {
  font-size: 14px;
  font-weight: 400;
  color: #797e82;
}

.trans-price {
  font-size: 18px;
  color: #171717;
  font-weight: 600;
}

.btn-block {
  width: 100%;
  background-color: #eeeef4 !important;
  border-radius: 10px !important;
  color: #171717 !important;
  font-weight: 500 !important;
  height: 50px !important;
}

.increment {
  font-size: 14px;
  color: #797e82;
  font-weight: 400;
}

.dashboard-footer {
  margin: 0 10rem;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 10px;
}

.loan-tag {
  background: #5f00d9;
  color: white;
  border-radius: 10px;
  display: inline;
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 500;
}

.dashfoot-content {
  font-size: 18px;
  font-weight: 500;
  color: #171717;
  padding-right: 5rem;
  margin-top: 1rem;
}

.contact-content {
  font-size: 18px;
  font-weight: 500;
  color: white;
  padding-right: 5rem;
  margin-top: 1rem;
}

.contact-tag {
  background: white;
  color: black;
  border-radius: 10px;
  display: inline;
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 500;
}

.export-area {
  padding: 0 10rem;
}

.table.bg {
  background-color: #f1f1f8;
}

.table-width {
  width: 85%;
  margin: 1rem auto;
  background: white;
  border-radius: 10px;
}

.thead-border {
  border-bottom: 1px solid lightgray;
  padding: 1rem !important;
}

.thead-tabs {
  gap: 1rem 1rem;
}

.table-tab {
  width: 105px;
  justify-content: center;
  border: 2px solid transparent;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.table-tab:hover {
  border-bottom: 2px solid blue;
  font-weight: bold;
}

.search-field {
  position: relative;
}

.search-input {
  width: 230px;
  padding-left: 1.5rem;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  font-size: 14px;
}

.search-input:focus-visible {
  border: none;
  outline: none;
}

.search-icon {
  font-size: 1.2rem;
  position: absolute;
  background-color: transparent;
  border: none;
  left: -5px;
  top: 2px;
  cursor: pointer;
}

.search-icon:hover {
  font-weight: bold;
}

.category {
  font-size: 12px;
  font-weight: 500;
  color: #797e82;
}

.table-tag {
  display: flex;
  padding: 4px 6px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 100px;
  background: #f3f3f7;
  font-size: 12px;
  font-weight: 500;
  text-shadow: 0 0 #797e82;
  color: #797e82;
}

.table-border {
  border-bottom: 1px solid lightgray !important;
}

.table-content {
  padding: 0 2rem;
}

.table-caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #797e82;
  text-shadow: #797e82;
  margin-bottom: 0;
}

.thead-content {
  color: #797e82;
  font-weight: 500;
  text-shadow: 0 0 #797e82;
}

.search-area {
  position: relative;
  text-align: right;
}

.search-field {
  border: none;
  height: 35px;
  font-size: 1.1rem;
}

.search-field:focus-visible {
  border: none;
  outline: none;
}

.search-icon {
  font-size: 1.5rem;
  position: absolute;
  right: 0;
}

.search-btn {
  background: transparent;
  border: none;
}

.admin-name {
  font-weight: bold;
  position: relative;
  top: 2px;
  right: 2px;
}

.btn-user:focus {
  box-shadow: none !important;
}

.btn-toggle:focus {
  box-shadow: none !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.graph-caption {
  font-weight: 500;
  text-shadow: 0 0 black;
}

.nav-btn {
  text-decoration: none;
  color: black;
  transition: color 0.3s linear;
  font-size: 1.2rem;
}

.nav-btn:hover {
  color: #43addb;
}

@media (min-width: 1400px) {
  .btn-toggle {
    display: none !important;
  }
}

@media (max-width: 1399px) {
  .btn-user {
    display: none !important;
  }

  .top-nav {
    padding: 1rem 2rem;
  }

  .sidebar {
    width: 0;
    overflow: hidden;
  }

  .dashboard-top {
    margin: 0rem 2rem;
  }

  .balance {
    gap: 3rem;
  }

  .transactions {
    margin: 2rem 2rem;
  }

  .dashboard-footer {
    margin: 0 2rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 1650px) and (max-width: 1880px) {
  .balance {
    gap: 5rem;
  }

  .dashboard-top {
    margin: 0 6rem;
  }

  .transactions {
    margin: 2rem 6rem;
  }

  .dashboard-footer {
    margin: 0 6rem;
  }
}

@media (min-width: 1400px) and (max-width: 1649px) {
  .balance {
    gap: 2rem;
  }

  .dashboard-top {
    margin: 0 2rem;
  }

  .transactions {
    margin: 2rem 2rem;
  }

  .dashboard-footer {
    margin: 0 2rem;
  }
}

@media (max-width: 1150px) {
  .transactions {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 770px) {
  .sidebar {
    position: fixed;
    background: white;
    top: 0;
    bottom: 0;
    z-index: 5;
    left: 0;
  }
}

@media (min-width: 450px) and (max-width: 920px) {
  .dashboard-top {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 449px) {
  .dashboard-top {
    grid-template-columns: repeat(1, 1fr);
    margin: 2rem 0.5rem;
  }

  .transactions {
    margin: 0rem 0.5rem;
  }

  .dashboard-footer {
    margin: 0rem 0.5rem;
  }
}

@media (max-width: 450px) {
  .search-area {
    text-align: left;
  }

  .top-nav {
    padding: 0.5rem;
  }

  .price-side {
    padding: 1.2rem 0.5rem;
  }
}

@media (max-width: 280px) {
  .search-field {
    width: 200px;
  }
}

@media (max-width: 300px) {
  .close-div {
    display: block !important;
    position: absolute;
    right: 35px;
    top: 5px;
  }
}

/* Base styles */
.cv-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  /* padding: 55px 0 0 0; */
}

.cv-select-container {
  display: flex;
  text-align: center;
  justify-content: center;
  border: 3.1px dashed #56367f73;
  flex-direction: column;
  max-width: 120vh;
  height: 40vh;
  border-radius: 12px;
  margin: auto;
  cursor: pointer;
  background-color: #f5f5f5;
}

.cv-select-container p {
  text-align: center;
  font-size: 16px;
}

.responsive-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .responsive-img {
    max-width: 50%;
  }
}

.cv-info-container {
  margin: 80px auto;
}

.cv-form-container {
  padding: 25px 55px 0 55px;
}

.cv-form-btn {
  border-radius: 5px;
  float: right;
}

.back-btn-cv {
  margin-right: auto;
}

.add-cv-btn,
.next-btn-cv {
  padding: 10px 30px;
}

@media (max-width: 407px) {
  .back-btn-cv {
    margin: auto;
    width: 100%;
    margin-right: 0 !important;
  }

  .add-cv-btn {
    margin: 10px auto !important;
    width: 100% !important;
    padding: 10px 120px !important;
  }

  .next-btn-cv {
    width: 100% !important;
    padding: 10px 120px !important;
  }
}

@media (max-width: 300px) {
  .cv-form-container {
    padding: 25px 15px 0 15px;
  }
}

/* CSS for the sliding animation */
.form-fade-enter {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.form-fade-enter-active {
  opacity: 1;
}

.form-fade-exit {
  opacity: 1;
  transition: opacity 1s ease-out;
}

.form-fade-exit-active {
  opacity: 0;
}

/* CSS for the cv form */
.cv-dropdown {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.dropdown-button-cv {
  padding: 5px 10px;
  background: #f1eff5;
  color: #5e5e7a;

  border: 1.67px solid #56367f73;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-menu-cv {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-item-cv {
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  color: #333;
}

.dropdown-item-cv:hover {
  background-color: #f0f0f0;
}

/* CSS for theSearch field in Reports */
.search-container {
  padding: 10px;
  border-radius: 11px;
  background-color: #ffffff;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 30px;
}

.search-input {
  border: none;
  outline: none;
  padding: 5px;
  background: none;
}

.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.search-button i {
  color: #333;
}

/* CSS for Predictive index */
.PI-container {
  padding: 2% 10%;
  margin-top: 50px;
}

.PI-Home-Input {
  flex: 0 0 calc(33.33% - 1rem);
  margin-bottom: 1rem;
}

.PI-checkbox {
  padding: 0 5% 0 5%;
}

.interview-home-container {
  display: flex;
  width: 78%;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  align-self: center;
}

@media screen and (max-width: 768px) {
  .interview-home-container {
    width: 95%;
  }
}

.interview-warmup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding-top: 20px;
}

.interview-home-card {
  width: 100%;
  margin: 0 auto;
  border-radius: 30px;
  padding: 50px 60px;
  box-shadow: 0px 2px 20px 12px rgb(0, 0, 0, 0.1);
}

@media (max-width: 575px) {
  .interview-home-card {
    padding: 6px 5px;
  }
}


.small-nav-items {
  text-decoration: none;
  font-size: 1rem;
  color: black;
  font-weight: bold;
}

.dropdown-progress {
  left: -30px !important;
}

.interview-btn {
  width: 50% !important;
  text-decoration: none;
}

.pi-top-main {
  margin-top: 0px;
}

.interview-qs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.interview-warmup-card {
  width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 30px;
  padding: 15px 40px;
}

.paper-interview {
  width: 100%;
  height: auto;

  border-radius: 30px !important;
  padding: 50px 40px;
  box-shadow: 0px 2px 20px 12px rgb(0, 0, 0, 0.1) !important;
}

.interview-card {
  width: 90%;
  height: auto;
  margin: 50px auto;
  border-radius: 30px !important;
  padding: 10px 30px;
  box-shadow: 0px 2px 20px 12px rgb(0, 0, 0, 0.1) !important;
}

/* Audio Tab Styling */
.audio-tab {
  margin-top: 20px;
  width: 70%;
  box-shadow: 0px 2px 20px 12px rgb(0, 0, 0, 0.1);
  border-radius: 30px;
}

.audio-tab::-webkit-media-controls-play-button {
  border-radius: 50%;
  background-color: #b1b1ff;
}

.audio-tab::-webkit-media-controls-play-button:hover {
  background-color: #9595f9;
}

.audio-tab::-webkit-media-controls-panel {
  background-color: white;
  color: black;
}

@media (max-width: 991px) {
  .interview-card {
    padding: 54px 80px !important;
  }
}

@media (max-width: 900px) {
  .interview-card {
    padding: 45px 70px !important;
  }
}

@media (max-width: 850px) {
  .interview-card {
    padding: 35px 35px !important;
  }
}

@media (max-width: 800px) {
  .interview-card {
    padding: 30px 25px !important;
  }
}

@media (max-width: 767px) {
  .interview-card {
    padding: 50px 80px !important;
  }
}

@media (max-width: 700px) {
  .interview-card {
    padding: 50px 60px !important;
  }
}

@media (max-width: 650px) {
  .interview-card {
    padding: 50px 35px !important;
  }
}

@media (max-width: 600px) {
  .interview-card {
    padding: 25px 18px !important;
  }
}

@media (max-width: 468px) {
  .interview-card {
    width: 100%;
    padding: 25px 5px !important;
  }
}

.interivew-screen-button {
  background-color: #5cb3cf;
  color: floralwhite;
  padding: 10px 20px;
  border-radius: 30px;
}

.table-pagination p {
  margin: 0;
}

.MuiTablePagination-select {
  background: rgba(80, 180, 130, 0.161) !important;
}

.interivew-screen-button:hover,
.interivew-screen-button.clicked {
  background-color: #5cb3cf;
  color: floralwhite;
}

.interview-qs-image {
  max-width: 25%;
  height: auto;
  margin: 0 auto;
  display: block;
}

@media (max-width: 1340px) {
  .interview-qs-image {
    max-width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .interview-qs-image {
    max-width: 70%;
  }
}

.interview-qs-audio-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.interview-qs-audio-buttons-small {
  margin-top: 10px;
  background-color: #5cb3cf;
  color: floralwhite;
}

.interview-qs-audio-buttons-large {
  margin-top: 12px;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #5cb3cf;
  color: floralwhite;
}

.project-button:hover,
.interview-qs-audio-buttons-large:hover,
.interview-qs-audio-buttons-large.clicked {
  background-color: #2bafdb;
  color: floralwhite;
}

.logout-btn {
  background-color: #5cb3cf;
  color: white;
  border-radius: 20px;
  width: 100px;
}

.verify-account-btn {
  background-color: #5cb3cf;
  color: white;
  border-radius: 20px;
  /* width: 100px; */
}

.faq-header {
  background-color: lightgray;
}

.interview-home-fields {
  display: flex !important;
  flex-direction: row !important;
}

/* .second-text-field {
  margin-left: 20px !important;
} */

@media (max-width: 768px) {
  .interview-home-fields {
    flex-direction: column !important;
  }

  /* .second-text-field {
    margin-left: 0px !important;
  } */
}

/* Validate OTP styling */
.admin-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-image: url("./assets/adminbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.bgWhite {
  width: 40rem;
  padding: 25px 55px;
  box-shadow: 0px 2px 20px 12px rgb(0, 0, 0, 0.1);
  border-radius: 30px;
  background-color: #fff;
}

.verify-title {
  font-weight: 600;
  margin-top: 70px;
  font-size: 24px;
  text-align: center;
}

.verify_otp_btn {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.customBtn {
  border-radius: 25px;
  padding: 12px 20px !important;
}

@media (max-width: 769px) {
  .customBtn {
    width: 50% !important;
  }
}

@media (max-width: 663px) {
  .fp-screen-container {
    width: 38rem;
  }
}

@media (max-width: 590px) {
  .fp-screen-container {
    width: 34rem;
    padding: 0px !important;
  }

  .customBtn {
    width: 30% !important;
  }
}

@media (max-width: 560px) {
  .fp-screen-container {
    width: 30rem;
    padding: 0px !important;
  }

  .customBtn {
    width: 32% !important;
  }

  .verify-form input {
    width: 46px !important;
  }
}

@media (max-width: 480px) {
  .fp-screen-container {
    width: 25rem;
    padding: 0px !important;
  }

  .customBtn {
    width: 35% !important;
    font-weight: 400;
  }

  .reset-btn span {
    font-size: 14px !important;
  }

  .verify-form input {
    width: 33px !important;
    height: 35px !important;
  }
}

@media (max-width: 370px) {
  .fp-screen-container {
    width: 22rem;
    padding: 0px !important;
  }

  .verify-form {
    padding: 0 18px !important;
  }

  .customBtn {
    width: 33% !important;
    font-weight: 400;
  }

  .reset-btn span {
    font-size: 12px !important;
  }

  .verify-form input {
    width: 36px !important;
    height: 40px !important;
  }
}

@media (max-width: 330px) {
  .fp-screen-container {
    width: 18rem;
    padding: 0px !important;
  }

  .verify-form {
    padding: 0 36px !important;
  }

  .customBtn {
    width: 42% !important;
    font-weight: 400;
  }

  .reset-btn span {
    font-size: 12px !important;
  }

  .verify-form input {
    width: 36px !important;
    height: 40px !important;
    margin-top: 12px !important;
  }
}

@media (max-width: 295px) {
  .reset-btn-1 {
    order: -1;
    /* Move reset-btn-1 to the top */
  }

  .reset-btn-2 {
    order: 1;
    /* Move reset-btn-2 to the bottom */
  }

  .fp-screen-container {
    width: 15rem !important;
    padding: 0px !important;
  }

  .verify-form {
    padding: 0 36px !important;
  }

  .verify_otp_btn {
    flex-direction: column;
    margin: 0 auto;
  }

  .btn-reset1,
  .btn-reset2 {
    width: 50% !important;
    font-weight: 400;
  }

  .btn-reset2 {
    margin-top: -9px !important;
  }

  .reset-btn span {
    font-size: 12px !important;
  }

  .verify-form input {
    width: 36px !important;
    height: 40px !important;
    margin-top: 12px !important;
  }
}

.otp-timer {
  text-align: right;
  margin-right: 50px !important;
}

.verify-form {
  padding: 0 50px;
}

.verify-form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  margin-left: 15px;
  border: 2px solid black;
}

.admin-reset-form {
  padding: 0 20%;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: #6e7881;
  border-radius: 7px !important;
  text-transform: uppercase;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: none !important;
  border-radius: 7px !important;
  background: initial;
  text-transform: uppercase;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%) !important;

  color: #fff;
  font-size: 1em;
}

/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
.css-1v4ccyo,
.css-1bn53lx {
  border-radius: 14px !important;
  border: 1px solid #5051fa !important;
  background-color: #f0f3f6;
}
.css-1x5jdmq {
  border-radius: 14px !important;
  border: 1px solid #5051fa !important;
  background-color: #f0f3f6;
} 
.css-4jnixx-MuiStack-root > .MuiTextField-root {
  min-width: 100% !important;
  border-radius: 0;
} */

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 20px !important;
}

.reset-password-top {
  padding: 20px;
}

.create-account {
  color: #000;
  font-size: 30px;
  font-style: normal;
  line-height: 183.2%;
  font-weight: 600;
}

.signup-p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  font-weight: 600;
}

/* Reset Password Screen */
.reset-btn {
  width: 40%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border-color: transparent;
  background: #5cb3cf;
}

.reset-text {
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 768px) {
  .reset-btn {
    width: 100%;
  }
}

.email-validation {
  margin-top: 10px;
  margin-bottom: -20px;
  color: #ea4335;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 183.2%;
  text-transform: capitalize;
}

/* Resend Email Screen */
.resend-email-top {
  margin: auto;
  justify-content: center;
  display: flex;
}

.resend-email-second {
  margin: 50px 0 0 0;
  text-align: center;
}

.resend-email-second .email-first {
  color: #000;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.desc-email-grey {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.desc-email-sample {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
}

.didnt-recieve-email {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 183.2%;
  margin-top: 50px;
}

.resend-email-btn {
  margin: auto;
  justify-content: center;
  display: flex;
  width: 60%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border-color: transparent;
  background: #66b855;
}

@media (max-width: 768px) {

  .resend-email-btn,
  .error-btn {
    width: 100%;
  }
}

.resend-email-text {
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.password-match {
  color: #66b855;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 183.2%;
  text-transform: capitalize;
}

.accordion-button.collapsed {
  background: none !important;
}

.css-1ygcj2i-MuiTableCell-root {
  font-weight: bold !important;
}

.education-table-row {
  margin: auto;
  justify-content: center;
  display: flex;
  text-align: center;
}

.edit-education-buttons {
  text-align: center;
  margin-top: 30px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.forget-password span {
  text-decoration: none;
  color: gray;
  cursor: pointer;
  float: right;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 183.2%;
}

/* Login Screen */
.signup-card,
.login-card,
.error-card,
.contactus-card {
  width: 40rem;
  padding: 25px 55px;
  box-shadow: 0px 2px 20px 12px rgb(0, 0, 0, 0.1);
  border-radius: 30px;
}

@media (max-width: 650px) {

  .login-card,
  .signup-card {
    width: 35rem;
    padding: 0px;
  }

  .signup-img {
    top: 22% !important;
    left: 75% !important;
  }
}

@media (max-width: 565px) {

  .login-card,
  .signup-card {
    width: 30rem;
    padding: 0px;
  }

  .login-img {
    display: none;
    top: 20% !important;
    right: 11px !important;
    margin-right: 20px !important;
  }

  .signup-img {
    top: 19% !important;
    left: 70% !important;
  }
}

@media (max-width: 480px) {

  .login-card,
  .signup-card {
    width: 25rem;
    padding: 0px;
  }

  .login-img {
    top: 15% !important;
    right: -33px !important;
    margin-right: 20px !important;
  }
}

@media (max-width: 375px) {
  .signup-img {
    top: 18% !important;
    left: 64% !important;
  }
}

@media (max-width: 300px) {
  .signup-img {
    top: 17% !important;
    left: 57% !important;
  }
}

.css-uhb5lp {
  border-radius: 20px !important;
  height: 300px !important;
  width: 400px !important;
  padding: 20px 0px !important;
}

.css-ohyacs {
  padding: 1px 24px !important;
}

@media (max-width: 340px) {
  .org-modal {
    margin-left: -14px;
    margin-right: 18px;
  }
}

@media (max-width: 320px) {
  .org-modal {
    margin-right: 30px;
  }
}

@media (max-width: 300px) {
  .org-modal {
    margin-right: 50px;
  }
}

.labels {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 183.2%;
}

.password-requirements {
  flex-shrink: 0;
  border-radius: 20px !important;
  background: #fff;
  border: 1px solid #ccc;
  padding: 20px !important;
  width: fit-content !important;
  z-index: 2;
}

.cpassword-field {
  position: relative;
}

.cpassword-field input {
  z-index: 1;
}

.password-requirements h5 {
  font-weight: 700;
  font-style: normal;
}

.css-sqs87j-MuiInputBase-root-MuiOutlinedInput-root,
.css-uhb5lp,
.css-1bp1ao6 {
  border-radius: 5px;
}

.list-no-style {
  list-style-type: none;
  margin-left: 0;
}

.css-ugk5tf .MuiStepConnector-line {
  height: 10px !important;
}

.css-dw6lbz-MuiFormControl-root-MuiTextField-root {
  margin-top: 0%;
}

/* this is */
.css-ph9425-MuiStepConnector-root .MuiStepConnector-line {
  height: 10px !important;
  border: 0;
  /* border-radius: 30px !important; */
}

.css-1ygcj2i-MuiTableCell-root,
.css-1ex1afd-MuiTableCell-root {
  text-align: center !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  padding: 20px !important;
  width: 480px !important;
  height: 350px !important;
  /* border-radius: 30px !important; */
}

.pi-warning {
  background: rgba(255, 0, 0, 0.15);
  backdrop-filter: blur(100px);
}

.pi-warning p {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 25px;
  color: #b70707;
}

.react-tel-input .form-control {
  padding: 20px 45px !important;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 5px !important;
  border: none !important;
  margin-left: 0;
  background: #f0f3f6 !important;
  line-height: 25px;
  height: 100% !important;
  width: 100% !important;
  outline: none;
}

.react-tel-input .country-list {
  min-height: 510px !important;
}

.terms {
  color: #66b855;
  font-weight: 700;
  text-decoration: none;
}

/* Terms & Conditions */
.tnc {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tnc-heading {
  display: flex;
}

.tnc-heading p {
  color: #333;
  font-size: 28px;
  font-family: "Roboto', sans-serif";
  font-weight: 800;
}

.tnc-last-p p {
  color: #6b6b6b;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin-top: -16px;
  margin-left: 21px;
}

.tnc-card {
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  border: none;
  width: 100%;
  height: 100%;
}

.tnc-card-p {
  color: #6b6b6b;
  text-align: justify;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.point-heading {
  width: 200px;
  word-break: break-all;
  white-space: normal;
}

@media screen and (max-width: 992px) {
  .point-heading {
    width: 160px;
  }
}

@media screen and (max-width: 768px) {
  .point-heading {
    width: 120px;
    word-break: keep-all;
  }
}

.not-selected-checkbox {
  color: red !important;
}

.css-1pxa9xg-MuiAlert-message {
  font-weight: 600 !important;
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
  color: #d32f2f !important;
  font-weight: 600 !important;
}

/* Add these styles to your CSS file or style block */
.verify-button {
  padding: 9px 30px;
}

.cancel-button {
  padding: 9px 50px;
}

/* div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm, */
div:where(.swal2-container) div:where(.swal2-actions):not(.swal2-loading) .swal2-styled {
  width: 165px;
}

/* .signup-rocket {
  margin-top: 20px;
} */

/* @media (max-width: 650px) {
  .signup-rocket {
    margin-left: 50px;
  }
} */

.signup-card,
.login-card,
.error-card,
.contactus-card {
  width: 40rem;
  padding: 25px 55px;
  box-shadow: 0px 2px 20px 12px rgb(0, 0, 0, 0.1);
}

.error-img {
  width: 65%;
}

@media (max-width: 640px) {

  .signup-card,
  .login-card,
  .error-card {
    padding: 18px;
    width: 30rem !important;
  }

  .contactus-card {
    padding: 18px;
  }
}

@media (max-width: 550px) {

  .signup-card,
  .login-card,
  .error-card {
    width: 25rem !important;
  }
}

@media (max-width: 430px) {

  .signup-card,
  .login-card,
  .error-card {
    width: 23rem !important;
  }
}

@media (max-width: 390px) {

  .signup-card,
  .login-card,
  .error-card {
    width: 20rem !important;
  }
}

@media (max-width: 350px) {

  .signup-card,
  .login-card,
  .error-card {
    width: 18rem !important;
  }

  .error-img {
    width: 70%;
  }
}

@media (max-width: 320px) {

  .signup-card,
  .login-card,
  .error-card {
    width: 16rem !important;
  }
}

@media (max-width: 280px) {

  .signup-card,
  .login-card,
  .error-card {
    width: 15rem !important;
  }
}

.resend-email-top {
  margin: auto;
  justify-content: center;
  display: flex;
}

.resend-email-second {
  margin: 50px 0 0 0;
  text-align: center;
}

.account-created-first {
  color: #000;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  margin-top: -40px;
}

@media (max-width: 430px) {
  .account-created-first {
    font-size: 20px;
  }
}

@media (max-width: 350px) {
  .account-created-first {
    font-size: 15px;
  }
}

@media (max-width: 280px) {
  .account-created-first {
    font-size: 13px;
  }
}

.error-desc-text-one,
.error-desc-text-two {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

@media (max-width: 768px) {
  .error-desc-text-one {
    display: none;
  }

  .error-desc-text-two {
    font-size: 16px;
  }
}

@media (max-width: 560px) {
  .error-btn {
    width: 60% !important;
  }

  .error-btn span,
  .error-desc-text-two {
    font-size: 14px !important;
  }
}

@media (max-width: 550px) {
  .error-btn {
    width: 70% !important;
  }

  .error-btn span {
    font-size: 12px !important;
  }
}

@media (max-width: 390px) {
  .error-btn {
    width: 65% !important;
  }

  .error-btn span {
    font-size: 10px !important;
  }
}

@media (max-width: 350px) {
  .error-btn {
    width: 75% !important;
  }

  .error-btn span {
    font-size: 10px !important;
  }

  .resend-email-second .email-first {
    font-size: 20px;
  }

  .resend-email-text {
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  .error-btn {
    width: 85% !important;
  }

  .error-btn span {
    font-size: 10px !important;
  }

  .didnt-recieve-email {
    font-size: 16px;
  }
}

.error-btn {
  margin: auto;
  justify-content: center;
  display: flex;
  width: 40%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border-color: transparent;
  background: #5cb3cf;
}

.error-btn span {
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.error-guide {
  text-align: center;
  justify-content: center;
  color: rgb(174, 167, 167);
  font-size: 14px;
}

.react-tel-input .flag-dropdown {
  /* border-radius: 30px 0 0 30px !important; */
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  background-color: #f0f3f6 !important;
  border: none !important;
}

.react-tel-input .selected-flag {
  width: 45px !important;
  padding: 0 0 0 13px !important;
  background-color: #f0f3f6 !important;
  border-radius: 14px !important;
}

.reactour__popover {
  width: 100% !important;
  height: auto !important;
  border-radius: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.3) 14px 0.5em 3em !important;
  border: 4px solid transparent;
  border-bottom-color: #04d39c;
}

.reactour__popover span {
  background-color: #2bafdb !important;
}

/* for dots */
.reactour__popover button[aria-label^="Go to step"] {
  background: #80808052 !important;
  padding: 4px !important;
}

.reactour__popover h3 {
  font-weight: bold;
}

.reactour__popover h3,
.reactour__popover p {
  color: #56367f !important;
}

.reactour__popover button[aria-label^="Go to step"][style*="background: var(--reactour-accent, #007aff);"] {
  background: #50b482 !important;
  outline: 2px solid #50b48261 !important;
  color: rgb(145, 22, 73) !important;
}

.reactour__close-button {
  width: 20px !important;
  height: 20px !important;
  color: white;
  background-color: #50b482 !important;
  border-radius: 50% !important;
  padding: 5px !important;
}

.no-scroll {
  overflow: hidden;
}

.step-heading {
  color: black;
}

.back-arrow-icon {
  margin-left: 50px;
  cursor: pointer;
}

.wheel {
  margin: 10px auto;
}

.percentage {
  font-size: 0.5em;
  text-anchor: middle;
}

h2 {
  margin-bottom: 0;
}

.blob-gradient {
  fill: url(#radial-gradient) rgb(255, 255, 255);
}

.blob-svg>svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
}

.card-header {
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%) !important;
}

.css-1bigob2 {
  font-weight: 600 !important;
}

/* .css-1bp1ao6 {
  border-radius: 30px !important;
} */

.contact-footer-card {
  height: fit-content;
  min-height: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid lightgray;
}

.contact-form-container {
  padding: 7% 10%;
  background-color: #ffffff;
  border-radius: 8px;
  width: 70%;
  height: fit-content;
  margin: auto;
  display: block;
  align-items: center;
  color: black;
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
}

@media (min-width: 700px) and (max-width: 1000px) {
  .contact-footer-card {
    margin-top: 50%;
  }
}

@media (max-width: 575px) {
  .contact-footer-card.first {
    margin-top: 50%;
  }
}

@media (max-width: 360px) {
  .contact-footer-card.first {
    margin-top: 100%;
  }
}

.pi-top-img {
  object-fit: cover;
  width: 100%;
  margin-top: 50px;
}

.pi-card {
  border-radius: 16px;
  background-color: white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  border: 2px solid lightgray;
}

.pi-team-dynamics-heading {
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
  margin-top: 20px;
}

.pi-detailed-container {
  height: 30vh;
}

.pi-section-container {
  padding: 10px 160px;
}

@media (max-width: 1600px) {
  .pi-section-container {
    padding: 10px 60px;
  }
}

.pi-card-title {
  font-size: 28px;
  font-weight: 600;
}

.pi-card-text {
  color: #787878;
  font-size: 18px;
  width: 70%;
}

.progres-labels {
  font-size: 30px;
}

.pi-img-detailed {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}

.pi-manag-container {
  margin-top: 10px;
}

.pi-img-managment {
  margin-top: -100px;
  width: 25%;
}

.pi-details-title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.bar-pi-details {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 10px;
}

.circular-text {
  transform: scaleX(-1);
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: -90px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.pi-title {
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "OpenSans", sans-serif;
  text-align: center;
}

.dynamic-img {
  width: 250px;
}

.pi-manag-title {
  font-size: 30px !important;
}

.pi-img-summary {
  margin-left: 40%;
  width: 340px;
}

.pi-img-org {
  width: 340px;
}

@media (max-width: 1500px) {
  .dynamic-img {
    width: 280px;
  }

  .pi-card-text {
    width: 100%;
  }

  .pi-personal-col {
    width: 100% !important;
  }

  .pi-img-managment {
    margin-top: -60px;
    width: 35%;
  }

  .pi-img-summary {
    margin-left: 20% !important;
    padding-bottom: 20px;
  }
}

@media (max-width: 1200px) {
  .pi-manag-col {
    width: 100% !important;
  }

  .pi-manag-title {
    font-size: 24px !important;
  }

  .pi-manag-text {
    font-size: 14px;
  }

  .pi-manag-row {
    flex-direction: column;
  }

  .pi-img-managment {
    margin-top: -60px;
    width: 15%;
  }

  .pi-manag-card {
    margin-top: 10%;
  }
}

@media (max-width: 1000px) {
  .pi-card {
    padding: 10px !important;
  }

  .pi-card-text {
    padding: 0px 10px;
  }

  .pi-col-5,
  .pi-col-7,
  .pi-card-text {
    width: 100%;
  }

  .pi-card-title {
    font-size: 30px;
  }

  .pi-col-5,
  .pi-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dynamic-img {
    margin-top: 5%;
    width: 25%;
  }

  .pi-dynamic-row {
    flex-direction: column !important;
  }

  .pi-img-summary {
    margin-left: 0% !important;
    width: 260px !important;
  }

  .pi-img-org {
    width: 240px;
  }

  .pi-skills-row,
  .pi-skills-padding {
    padding: 12px !important;
  }
}

@media (max-width: 950px) {
  .pi-section-container {
    padding: 20px !important;
  }

  .pi-team-dynamics-heading {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .pi-manag1-card {
    margin-top: 5%;
  }
}

@media (max-width: 620px) {
  .pi-img-managment {
    width: 20%;
  }

  .pi-manag-title {
    font-size: 22px !important;
  }

  .pi-personality {
    flex-direction: column !important;
  }
}

@media (max-width: 480px) {
  .pi-img-managment {
    width: 30%;
  }

  .pi-manag-container {
    margin-top: -20px;
  }
}

.pi-skills-row {
  flex-direction: row !important;
  padding: 0px 40px !important;
}

.pi-skills-padding {
  padding: 0px 40px !important;
}

@media (max-width: 575px) {
  .dynamic-img {
    width: 200px;
  }

  .pi-card-text {
    width: 100%;
  }

  .pi-personal-col,
  .pi-col-7,
  .pi-col-5 {
    width: 100% !important;
  }

  .pi-skills-row {
    flex-direction: column !important;
  }

  .row-personal-dev {
    flex-direction: column;
  }

  .pi-img-managment {
    margin-top: -10%;
  }
}

/* .pi-detailed-card:hover {
  background-color: #000;
  color: red;
} */

.pi-detailed-card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.pi-detailed-card.flipped {
  transform: rotateY(180deg);
}

.back-side {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.front-side {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.back-side {
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__side {
  height: 52rem;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}

.card__side--front {
  background-color: #fff;
}

.card__side--front-1 {
  background: linear-gradient(-45deg, #f403d1, #64b5f6);
}

.card__side--front-2 {
  background: linear-gradient(-45deg, #f321d7, #ffec61);
}

.card__side--front-3 {
  background: linear-gradient(-45deg, #24ff72, #9a4eff);
}

.card__side--back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card__side--back-1 {
  background: linear-gradient(-45deg, #64b5f6, #f403d1);
}

.card__side--back-2 {
  background: linear-gradient(-45deg, #ffec61, #f321d7);
}

.card__side--back-3 {
  background: linear-gradient(-45deg, #9a4eff, #24ff72);
}

.card:hover .card__side--front-1,
.card:hover .card__side--front-2,
.card:hover .card__side--front-3 {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.card:hover .card__side--back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

.card__title {
  height: 20rem;
  padding: 4rem 2rem 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card__title--1 .fas {
  font-size: 5rem;
}

.card__title--2 .fas {
  font-size: 5rem;
}

.card__title--3 .fas {
  font-size: 5rem;
}

.card__heading {
  font-size: 4rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  width: 75%;
}

.card__heading-span {
  padding: 1rem 1.5rem;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.card__details {
  padding: 0 2rem 2rem;
}

.card__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}

.card__details ul li {
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem;
}

.card__details ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
  text-align: center;
}

.card__price-box {
  text-align: center;
  color: #fff;
  margin-bottom: 8rem;
}

.card__price-only {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.card__price-value {
  font-size: 6rem;
  font-weight: 100;
}

/* Styles for the scrollbar */
.bar-pi-details::-webkit-scrollbar {
  width: 8px;
  border-radius: 30px;
}

.bar-pi-details::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.bar-pi-details::-webkit-scrollbar-thumb {
  background-color: #2bafdb;
  border-radius: 30px;
  border: 2px solid transparent;
}

/* Firefox scrollbar styles */
.bar-pi-details {
  scrollbar-color: #2bafdb #f1f1f1;
  scrollbar-width: thin;
}

.pi-small-img {
  width: 140px;
}

.pi-grid-work-img {
  width: 120px;
}

.pi-large-img {
  width: 40%;
}

.back-side-card-body {
  margin-top: -100px;
}

.trophy {
  width: 35px;
}

.pi-personal-img {
  width: 160px !important;
}

.pi-grid-section0 {
  color: #7c7c7c;
  font-size: 28px;
  display: flex;
  font-weight: 700;
}

.pi-last-card {
  padding: 20px;
}

@media (max-width: 1700px) {
  .label-grid {
    width: 30% !important;
  }
}

@media (max-width: 1500px) {
  .label-grid {
    width: 40% !important;
  }
}

@media (max-width: 1176px) {
  .label-grid {
    width: 50% !important;
  }
}

@media (max-width: 994px) {
  .label-grid {
    width: 60% !important;
  }
}

@media (max-width: 1000px) {
  .label-grid {
    width: 25%;
  }
}

@media (max-width: 1000px) {
  .pi-detailed-card {
    height: 20vh;
  }

  .pi-img-summary {
    margin-left: 0px;
  }

  .back-side-card-body {
    margin-top: 0px;
  }

  .pi-detail-container {
    margin-top: -50px !important;
  }

  .pi-human {
    width: 15%;
  }

  .pi-dynamic-small-row {
    text-align: center;
    justify-content: center;
    margin: auto;
    padding-bottom: 30px;
  }

  .pi-animation {
    text-align: center;
    justify-content: center;
    margin: auto;
  }

  .pi-grid-section0 {
    display: block !important;
  }

  .trophy {
    width: 30px;
  }

  .pi-last-card {
    padding: 12px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 22px;
  padding: 10px;
}

@media (max-width: 1360px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 873px) {
  .label-grid {
    width: 70% !important;
  }
}

@media (max-width: 800px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .flip-card {
    margin-top: 12px !important;
  }
}

@media (max-width: 787px) {
  .label-grid {
    width: 80% !important;
  }
}

@media (max-width: 767px) {
  .pi-prsonal-card {
    margin-top: 5%;
  }

  .report-tabs {
    font-size: 12px !important;
  }
}

@media (max-width: 722px) {
  .label-grid {
    width: 90% !important;
  }
}

@media (max-width: 671px) {
  .label-grid {
    width: 100% !important;
  }
}

@media (max-width: 631px) {
  .label-grid {
    grid-template-columns: repeat(1, 1fr) !important;
    width: 40% !important;
  }
}

@media (max-width: 537px) {
  .label-grid {
    width: 60% !important;
  }
}

@media (max-width: 447px) {
  .label-grid {
    width: 70% !important;
  }
}

@media (max-width: 570px) {
  .pi-title {
    margin-top: 0px;
  }
}

@media (max-width: 575px) {
  .pi-detailed-card {
    margin-top: 5%;
  }

  .pi-personal-img {
    width: 140px !important;
  }
}

.pronunciation-img {
  width: 50px;
}

.oral-pronunciation-qs {
  display: flex;
  color: #4d4d4d;
  justify-content: space-between;
}

.pronunciation-speaking-titles {
  font-weight: 700;
  font-size: 18px;
}

.pronunciation-speaking {
  font-size: 14px;
  color: #787878;
}

.pronunciation-speaking-details {
  font-weight: 700;
  color: #4d4d4d;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
  border-radius: 16px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 300px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  text-align: center;
  margin: auto;
  border-radius: 16px;
}

.flip-card-front {
  background-color: #f9f7f7b5;
  color: black;
}

.flip-card-back {
  background-color: #56367f;
  color: white;
  transform: rotateY(180deg);
  text-align: center;
}

.flip-card-back-dimensions {
  margin: 20px auto;
  height: auto;
  overflow: auto;
}

@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.report-tabs {
  font-weight: 600;
  font-size: 1.1rem;
  transition: font-size 0.3s ease-in-out;
}

.label-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  justify-content: start;
  align-items: center;
}

.grid-item {
  color: white;
  border-radius: 20px;
  text-align: center;
  width: auto;
  font-weight: bold;
}

.mistake {
  color: red;
}

.speech-error {
  color: #f0cd08;
}

.suggestion {
  color: #008052;
}

.proun-img {
  width: 40px;
}

.proun-img-mic {
  width: 20px;
}

.grid-container-voc {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 15px 0;
  text-align: center;
}

.grid-item-voc {
  border-radius: 13px;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.grid-item-voc.card {
  border: 1.18px solid #b7b7b7;
}

@media (max-width: 1000px) {
  .grid-container-voc {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575px) {
  .grid-container-voc {
    grid-template-columns: repeat(1, 1fr);
  }
}

.voc-titles {
  font-weight: 500;
}

/* Guage meter */
.gauge-meter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gauge-background {
  fill: none;
  stroke: #eee;
  stroke-width: 20;
}

.gauge-section {
  fill: none;
  stroke-width: 20;
}

.green-section {
  stroke: #4caf50;
}

.blue-section {
  stroke: #2196f3;
}

.yellow-section {
  stroke: #ffc107;
}

.red-section {
  stroke: #f44336;
}

.pink-section {
  stroke: #e91e63;
}

.needle {
  stroke: #333;
  stroke-width: 3;
}

.needle-base {
  fill: #333;
}

.speedometer {
  width: 500px;
  height: 500px;
  padding: 100px;
  border-radius: 50%;
  background: radial-gradient(#ffffff 0) content-box,
    conic-gradient(var(--c1) 36deg,
      var(--c2) 72deg,
      var(--c3) 108deg,
      var(--c4) 144deg,
      var(--c5) 180deg,
      #000000 0);
  position: relative;
}

.needle {
  position: absolute;
  width: 380px;
  height: 380px;
  padding: 150px;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  border-radius: 50%;
  background: conic-gradient(from 175deg at 50% 55%,
      #ccc 2deg,
      #eac198 3deg 7deg,
      #ccc 8deg 10deg,
      #000 0) -217px no-repeat,
    radial-gradient(#eac198 0 50%, #ccc 51%) content-box;
  rotate: calc(cos(var(--score) * 180deg / 100) - 90deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.score {
  rotate: calc(cos(var(--score) * 180deg / 100) - 90deg);
  font-size: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 800;
  color: #eee;
}

.apex-chart-guage {
  height: 400px !important;
  width: 400px !important;
}

.oral-container {
  padding: 45px;
}

.intrviewqs {
  padding: 12px;
}

@media (max-width: 767px) {
  .oral-container {
    padding: 5px;
  }
}

@media (max-width: 395px) {
  .interview-warmup-card {
    padding: 0px !important;
  }

  .terms-header-container p {
    width: 80% !important;
    letter-spacing: 2px !important;
    padding: 7px 10px !important;
  }

  .paper-interview {
    padding: 30px 20px !important;
  }
}

.css-1xsto0d {
  font-weight: 600 !important;
}

/* new login page styling */

.login-page-container {
  min-height: 100vh;
}

.login-form-logo {
  width: 12rem;
  height: auto;
  object-fit: contain;
  filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.9));
}

.login-form-container {
  background-color: white;
  border-radius: 30px;
  padding: 0px 25px !important;
  font-family: "OpenSans", sans-serif;
}

.login-form-body h4 {
  font-size: 2rem;
  font-family: "Onest", sans-serif;
}

.login-form-input label {
  color: gray;
  margin-left: 5px;
}

.login-form-input input,
.otp-form-container input {
  border-radius: 5px;
  padding: 15px 18px;
  background-color: #f0f3f6;
  border: none;
}

.login-form-input input:focus {
  outline: none;
}

.login-form-input input.password {
  border: none;
  background-color: #f0f3f6;
}

input.password:focus {
  box-shadow: none;
  border-right: none;
}

.input-group {
  border-radius: 5px;
  background-color: #f0f3f6;
}

.input-group-text {
  background-color: transparent;
  border: none;
  border-radius: 14px;
}

/* Basic styling for the checkbox */
.custom-checkbox {
  appearance: none;
  /* Remove default styles */
  width: 15px;
  height: 15px;
  border: 2px solid #b3b7ee;
  /* Default border color */
  border-radius: 4px;
  /* Optional: round corners */
  position: relative;
  cursor: pointer;
  padding: 2px;
}

/* Styling for the checked state */
.custom-checkbox:checked {
  background-color: #5051fa;
  border-color: #5051fa;
  color: white;
  /* Change to your desired color */
}

/* Optional: Styling for a checkmark */
.custom-checkbox:checked::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-form-button {
  padding: 15px 25px;
  color: white;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  border-radius: 7px;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
}

.login-form-button.back-btn-cv {
  background: gray !important;
  font-weight: 500;
}

.welcome-audio-buttons button {
  padding: 12px 20px;
  color: white;
  border: none;
  border-radius: 12px;
  background-color: #5c7ce5;
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
}

.login-form-button.disabled {
  background: gray;
}

.login-form-button.text-decoration-none:hover {
  color: white;
}

.login-page-right {
  position: relative;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  border-radius: 27px;
  overflow: hidden;
}

.login-search-img {
  width: 75%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.polygon-top-left {
  width: 50px;
  height: auto;
  position: absolute;
  top: -1%;
  left: -20px;
}

.cells-top-right {
  height: 100px;
  width: auto;
  position: absolute;
  top: 1%;
  right: 3%;
}

.polygon-bottom-left {
  height: 140px;
  width: auto;
  position: absolute;
  bottom: 0%;
  left: -30px;
}

.polygon-bottom-right {
  height: 180px;
  width: auto;
  position: absolute;
  bottom: 0%;
  right: -60px;
}

.login-stats {
  position: absolute;
  width: 90%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1280px) {
  .login-stats {
    width: 100% !important;
  }

  .login-page-container {
    min-height: 90vh !important;
    max-height: 90vh !important;
    align-self: center;
  }

  .signup-right-girl {
    height: 250px !important;
    width: auto;
    transform: translateX(-20px) !important;
  }
}

@media screen and (max-height: 680px) {
  .signup-right-girl {
    height: 200px !important;
    width: auto;
    transform: translateX(0px) !important;
  }
}

.login-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.password-textfield {
  background-color: #f0f3f0;
  border-radius: 5px;
}

.signup-right-girl {
  position: absolute;
  bottom: 0;
  height: 350px;
  width: auto;
  object-fit: contain;
  left: 30%;
  background-size: contain;
}

.signup-blocks {
  position: absolute;
  transform: translate(-50%, -30%);
  left: 30%;
}

/* otp page styling */

.otp-form-container input {
  border-radius: 14px;
  border: 1px solid #56367f;
  padding: 15px 18px;
}

.otp {
  background-color: rgba(92, 124, 229, 0.15);
  border-radius: 14px;
  border: none;
  font-family: "Onest", sans-serif;
  font-weight: bold;
}

.otp:focus {
  background-color: white;
  outline: 1px solid #8a56ce;
  color: #56367f !important;
}

.verify-otp-form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  color: black;
}

.verify-otp-form input:focus {
  color: #8e53db !important;
}

.phone_input {
  border: none !important;
  outline: none !important;
  stroke: none !important;
}

.terms-header-container {
  width: 100%;
  height: 350px;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  position: relative;
  clip-path: url(#rounded-bottom-clip);
  overflow: hidden;
}

.terms-header-container h1 {
  font-family: "OpenSans", sans-serif;
  font-size: 3rem;
}

.terms-header-container p {
  position: relative;
  color: #00e18e !important;
  background: #50b4825c;
  font-weight: bold;
  letter-spacing: 6px;
  line-height: 25px;
  text-transform: uppercase;
  padding: 7px 30px;
  font-size: 14px;
  font-family: "OpenSansCondensed", sans-serif;
  border-radius: 7px;
  border: 1px solid #00e18e;
}

@media (max-width: 768px) {
  .terms-header-container {
    clip-path: url(#rounded-bottom-clip-mobile);
  }

  .tc-topleft-poly {
    position: absolute;
    height: 100px !important;
    top: -50px !important;
    left: -40px !important;
  }

  .tc-bottomleft-poly {
    position: absolute;
    height: 90px !important;
    bottom: -27px !important;
  }

  .tc-bottomleft-poly2 {
    position: absolute;
    height: 90px !important;
    bottom: -40px !important;
  }

  .tc-right-poly {
    position: absolute;
    height: 90px !important;
    right: -30px !important;
  }

  .tc-right-poly2 {
    position: absolute;
    height: 90px !important;
    right: -60px !important;
  }
}

@media (min-width: 769px) {
  .terms-header-container {
    clip-path: url(#rounded-bottom-clip);
  }
}

.terms-page p {
  color: #212529;
}

.tc-topleft-poly {
  position: absolute;
  height: 180px;
  width: auto;
  top: -80px;
  left: -60px;
}

.tc-bottomleft-poly {
  position: absolute;
  height: 150px;
  width: auto;
  bottom: -50px;
  left: 20%;
}

.tc-bottomleft-poly2 {
  position: absolute;
  height: 150px;
  width: auto;
  bottom: -80px;
  left: 20%;
}

.tc-blocks {
  position: absolute;
  height: 80px;
  width: auto;
  top: 5%;
  right: 20%;
}

.tc-blocks2 {
  position: absolute;
  height: 80px;
  width: auto;
  top: 5%;
  right: 38%;
}

.tc-cells {
  position: absolute;
  height: 70px;
  width: auto;
  top: -15px;
  right: 8%;
}

.tc-right-poly {
  position: absolute;
  height: 150px;
  width: auto;
  bottom: 30%;
  right: -50px;
}

.tc-right-poly2 {
  position: absolute;
  height: 150px;
  width: auto;
  top: 10%;
  right: -120px;
}

.terms-point-list {
  list-style-type: none;
  padding-left: 5px;
}

.terms-point-list li {
  margin-left: 0px !important;
}

.terms-list-heading::marker {
  font-size: 26px !important;
  font-weight: 500;
}

.terms-list-heading h3 {
  font-weight: 500;
}

.terms-back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 10%;
  left: 4%;
  cursor: pointer;
}

.terms-back-icon {
  margin-left: 6px;
}

.MuiStepIcon-text {
  color: white !important;
}

.swal2-confirm {
  background-color: black !important;
}

.swal-icon {
  border: 0 !important;
}

.navigate-report-btn {
  background: #50b482;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bolder;
  font-size: 16px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: none;
}

.report-card {
  border: 1px solid rgb(234, 234, 234);
}

/* css styling for tour buttons on interview questions page */

button[data-test-id="button-skip"] {
  font-weight: 600 !important;
  border-radius: 7px !important;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
  color: white;
  cursor: pointer;
  /* Add any other custom styles here */
}

button[data-test-id="button-back"] {
  font-weight: 600 !important;
  border-radius: 7px !important;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
  color: white;
  cursor: pointer;
}

button[data-test-id="button-primary"] {
  font-weight: 600 !important;
  border-radius: 7px !important;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
  color: white;
  cursor: pointer;
}

.fa-arrow-right,
.btn-outline-info {
  color: #56367f !important;
}

.form-check-input:checked {
  background-color: #56367f;
  border-color: #56367f;
}

/* progress stepper styling  */

.progress-stepper-container {
  width: 50%;
  height: 120px;
  background: linear-gradient(180deg, #56367f 0%, #3a5697 100%);
  border-radius: 60px;
}

.progress-step h3 {
  font-weight: 700;
  font-style: italic;
  color: white;
}

.progress-step p {
  font-weight: 600;
  font-size: 14px;
  color: white;
}

.progress-step.active {
  height: 150px;
  background: #50b482;
  transform: skew(-20deg);
  border-radius: 5px;
}

.progress-step.active h3,
.progress-step.active p {
  transform: skew(20deg);
  position: relative;
}

@media (max-width: 1690px) {
  .progress-stepper-container {
    width: 65%;
  }
}

@media (max-width: 1305px) {
  .progress-stepper-container {
    width: 90%;
  }
}

@media (max-width: 700px) {
  .progress-stepper-container {
    width: 95%;
  }
}

@media (max-width: 948px) {
  .progress-step.active {
    margin-top: 12px;
  }
}

@media (min-width: 387px) and (max-width: 425px) {
  .progress-step.step4 {
    margin-top: 12px;
  }
}

@media (max-width: 600px) {
  .progress-step h3 {
    font-weight: 600;
    font-size: 16px;
  }

  .progress-step p {
    font-weight: 600;
    font-size: 10px;
  }
}

@media (max-width: 500px) {
  .progress-stepper-container {
    height: 100px;
  }

  .progress-step.active {
    height: 120px;
    transform: skew(-10deg);
  }

  .progress-step.active h3,
  .progress-step.active p {
    transform: skew(10deg);
    position: relative;
  }
}

.interview-guide-button {
  position: fixed;
  bottom: 2%;
  right: 2%;
  padding: 10px 25px;
  color: white;
  border: 1px solid white;
  font-weight: 600;
  border-radius: 7px;
  background: linear-gradient(90deg, #56367f 0%, #3a5697 100%);
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
}

div.__floater .__floater__open {
  display: none !important;
}

.dropdown-menu.dropdown-lang-menu {
  margin-left: -10% !important;

}

@media screen and (max-width: 768px) {
  .interview-img-logo {
    width: 150px !important;
  }

  .dropdown-menu.dropdown-lang-menu {
    margin-left: -20% !important;
  }
}

@media screen and (max-width: 545px) {
  .dropdown-menu.dropdown-lang-menu {
    margin-left: -100% !important;
  }
}


.dropdown-menu.dropdown-lang-menu,
.dropdown-menu.dropdown-profile-menu {
  border: 2px solid #8139e0;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    border-color: #8139e0;
  }

  50% {
    border-color: #d770c1;
  }

  100% {
    border-color: #8139e0;
  }
}


.contact-form-main {
  width: 70%;
  align-self: center;
}

@media screen and (max-width: 768px) {
  .contact-form-main {
    width: 90%;
    margin: auto 10px !important;
    padding: 10% 10% !important;
  }
}

.dragndrop-overlay{
  background: #56367f;
  opacity: 0.5;
  border-radius: 12px;
}

.cv-file-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
}


.fade-in {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
